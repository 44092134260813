// @flow

import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { rem } from 'polished'
import styled from '@emotion/styled'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import Options from '@/components/Options'
import { mq } from '@/styles/theme'
import { LinkButton } from '@/components/Button'
import OffsetAnchor from '@/components/OffsetAnchor'
import Location from '@/components/Location'
import SectionNav from '@/components/SectionNav'
import VideoModalCTA from '@/components/VideoModalCTA'
import { dynamicRow, TitleStyles } from '@/styles/shared'
import LayoutContainer from '@/components/LayoutContainer'
import { linkResolver } from '@/config/linkResolver'
import nanoid from 'nanoid'
import { Image } from '@/components/Image'

const OptionsStyles = css`
  background: var(--c-veryLightPink);
  padding: 100px 0;
`

type Props = {
  data: Object,
}

const getLocationText = count => (count > 1 ? 'Locations' : 'Location')

function SkilledNursingPage(props: Props) {
  const {
    data: {
      page: {
        data: {
          heroTitle,
          heroDescription: { html: heroDescription },
          heroPhoto: { url: heroPhoto },
          heroCtas,
          servicesTitle,
          services,
          amenitiesTitle,
          amenitiesDescription: { html: amenitiesDescription },
          listLayoutName,
          amenities,
          featuresTitle,
          rehabSupplemental,
          whatToBring,
          featuresDescription: { html: featuresDescription },
          featuresPhoto,
          nursingSupplemental,
          locations,
        },
      },
    },
  } = props
  const newRehabSection = rehabSupplemental.filter(filterNewSection)

  const locationsCount = locations.length
  const locationCopyHelper = getLocationText(locationsCount)
  const locationCopyLowercase = locationCopyHelper.toLowerCase()

  const anchorLinks = [
    {
      label: 'Overview',
      id: 'overview',
    },
    {
      label: locationCopyHelper,
      id: locationCopyLowercase,
    },
    {
      label: 'Amenities',
      id: 'amenities',
    },
    {
      label: 'Suites',
      id: 'suites',
    },
    {
      label: 'Nursing',
      id: 'nursing',
    },
    {
      label: 'Rehab',
      id: 'rehab',
    },
  ]

  return (
    <Layout>
      <SectionNav title={heroTitle} navLinks={anchorLinks} />
      <OffsetAnchor offset={-100} id="overview">
        <Feature
          css={FeatureStyles}
          hero={true}
          titleAccent
          largeTitle
          description={
            <>
              <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
              {heroCtas && heroCtas.length && (
                <Ctas
                  css={css`
                    margin-top: 20px;
                  `}>
                  {heroCtas.map(data => {
                    const { label, type, link } = data
                    const isTelephone = link?.url?.includes('tel')
                    // console.log(data)
                    if (type === 'link' && !isTelephone) {
                      return (
                        <LinkButton
                          key={`link-button-${label}`}
                          to={linkResolver(link)}>
                          {label}
                        </LinkButton>
                      )
                    } else if (type === 'link' && isTelephone) {
                      return (
                        <LinkButton key={`link-button-${label}`} to={link.url}>
                          {label}
                        </LinkButton>
                      )
                    } else if (type === 'video') {
                      return (
                        <VideoModalCTA
                          key={`link-button-${label}`}
                          ctaLabel={label}
                          videoTitle={label}
                          videoSrc={link}
                        />
                      )
                    }
                  })}
                </Ctas>
              )}
            </>
          }
          mediaBlock={<>{heroPhoto && <MediaBlock src={heroPhoto} alt="" />}</>}
        />
      </OffsetAnchor>
      <OffsetAnchor offset={-100} id={locationCopyLowercase}>
        {!(locations && locations.length)
          ? null
          : locations.map(
              (
                { google_maps_url1, location_title1, location_description1 },
                index
              ) => (
                <Location
                  key={`map-${index}`}
                  subtitle={index === 0 ? 'Locations' : ' '}
                  title={location_title1}
                  description={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: location_description1.html,
                      }}
                    />
                  }
                  mapSrc={google_maps_url1}
                />
              )
            )}
      </OffsetAnchor>
      <OffsetAnchor offset={-100}>
        <Options
          css={OptionsStyles}
          layout="detailed"
          subtitle="Key Feature"
          title={servicesTitle}
          options={services.map(
            ({
              icon: { url: icon },
              description: { html: description },
              title,
            }) => ({
              icon: { src: icon },
              description,
              title,
            })
          )}
        />
      </OffsetAnchor>
      <OffsetAnchor offset={-100} id="amenities">
        <Container>
          <Options
            title={amenitiesTitle}
            titleAccent
            description={
              <div dangerouslySetInnerHTML={{ __html: amenitiesDescription }} />
            }
            options={amenities.map(({ icon: { url: icon }, title }) => ({
              icon: { src: icon },
              title,
            }))}
            layout={listLayoutName}
          />
        </Container>
      </OffsetAnchor>
      <OffsetAnchor offset={-100} id="suites">
        <Feature
          subtitle="Key Feature"
          title={featuresTitle}
          description={
            <div dangerouslySetInnerHTML={{ __html: featuresDescription }} />
          }
          mediaBlock={
            featuresPhoto.url ? (
              <MediaBlock
                src={featuresPhoto.url}
                alt=""
                ctaLabel="Watch Video"
              />
            ) : null
          }
        />
      </OffsetAnchor>
      <OffsetAnchor id="nursing" offset={-100}>
        {nursingSupplemental && (
          <BottomContentLayoutContainer>
            <div css={[dynamicRow, BottomContentColStyles]}>
              {nursingSupplemental.map(
                ({
                  primary: {
                    title,
                    copy: { html: copy },
                  },
                  // items,
                }) => (
                  <div key={`supplement-${title}`}>
                    {title && <Title titleAccent>{title}</Title>}
                    {copy && (
                      <div
                        className="large"
                        dangerouslySetInnerHTML={{ __html: copy }}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </BottomContentLayoutContainer>
        )}
      </OffsetAnchor>
      <OffsetAnchor id="rehab" offset={-100}>
        {newRehabSection && (
          <BottomContentLayoutContainer>
            {newRehabSection.map(
              ({
                primary2: {
                  title,
                  copy: { html: copy },
                  copy2: { html: copy2 },
                  image: { url: imageUrl },
                  whatToBring: { html: whatToBring, text: whatToBringText },
                },
                // items,
              }) => {
                return (
                  <OffsetAnchor offset={0} key={`supplement-${nanoid()}`}>
                    {title && (
                      <TitleWithLogoLayout>
                        <Title titleAccent>{title}</Title>&nbsp;&nbsp;
                        <PowerbackLogo src={imageUrl} alt="" />
                      </TitleWithLogoLayout>
                    )}
                    {copy && (
                      <div
                        className="large"
                        dangerouslySetInnerHTML={{ __html: copy }}
                      />
                    )}
                    <TwoColumnSection>
                      {copy2 && (
                        <Copy2StylesContainer
                          className="large"
                          dangerouslySetInnerHTML={{ __html: copy2 }}
                        />
                      )}
                      {whatToBringText?.length > 0 ? (
                        <WhatToBringSection
                          dangerouslySetInnerHTML={{ __html: whatToBring }}
                        />
                      ) : null}
                    </TwoColumnSection>
                  </OffsetAnchor>
                )
              }
            )}
          </BottomContentLayoutContainer>
        )}
      </OffsetAnchor>
    </Layout>
  )
}

const filterNewSection = sec => sec?.slice_type === 'text__1_logo__1_photo_2'

export default SkilledNursingPage

const Container = styled(LayoutContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const TitleWithLogoLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const PowerbackLogo = styled(Image)`
  grid-area: powerbacklogo;
  padding: 2rem 6rem;
  width: 20%;

  ${mq.greaterThan('small')`
    padding: 1rem 0;
  `}
`

const Copy2StylesContainer = styled.div`
  padding-right: 4rem;
  grid-area: copy2;
`

const WhatToBringSection = styled.div`
  grid-area: what2;
  padding: 2rem;
  height: min-content;
  background: var(--c-veryLightPink);
`

const TwoColumnSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    'copy2'
    'what2';

  ${mq.greaterThan('small')`
    grid-template-columns: 55% 45%;
    grid-template-rows: 200px 1fr;
    grid-template-areas:  'copy2 what2'
                          'copy2 what2';
  `}
`

const Ctas = styled.div`
  display: grid;
  grid-gap: 15px;
`

const FeatureStyles = css`
  && {
    padding: 30px 0;
  }
`

const BottomContentLayoutContainer = styled(LayoutContainer)`
  margin-bottom: 100px;
  color: var(--c-gunmetal);

  p {
    font-size: ${rem('18px')};
    line-height: 1.5;

    &.large {
      font-size: ${rem('24px')};
    }
  }
`

const BottomContentColStyles = css`
  ${mq.lessThan('small')`
    grid-gap: 60px 0;
  `};
`

const BottomContentButtonStyles = css`
  ${mq.lessThan('small')`
    grid-gap: 15px 0;
  `};
`

const Title = styled.h2`
  ${TitleStyles};
  margin-bottom: 0;
  position: relative;

  ${props =>
    props.titleAccent &&
    `&::before {
    content: '';
    display: block;
    width: 55px;
    height: 5px;
    background: var(--c-lightGreyBlue);
    position: absolute;
    top: -25px;
  }`}
`

export function Head({
  data: {
    page: {
      data: {
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}) {
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  )
}

export const query = graphql`
  query ($uid: String!) {
    page: prismicSkilledNursingResidencePage(uid: { eq: $uid }) {
      data {
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
        heroTitle: hero_title
        heroDescription: hero_description {
          html
        }
        heroPhoto: hero_photo {
          url
        }
        heroCtas: hero_ctas {
          label
          type
          link {
            uid
            type
            url
          }
        }
        locations {
          google_maps_url1
          location_title1
          location_description1 {
            html
          }
        }
        servicesTitle: services_title
        services {
          icon: service_icon {
            url
          }
          title: service_title
          description: service_description {
            html
          }
        }
        amenitiesTitle: amenities_title
        amenitiesDescription: amenities_description {
          html
        }
        listLayoutName: list_layout_name
        amenities {
          title: amenity_name
          icon: amenity_icon {
            url
          }
        }
        featuresTitle: features_title
        featuresDescription: features_description {
          html
        }
        featuresPhoto: features_photo {
          url
        }
        rehabSupplemental: rehab_supplemental {
          ... on PrismicSkilledNursingResidencePageDataRehabSupplementalText1Logo1Photo2 {
            id
            slice_type
            primary2: primary {
              title: supplement_title
              copy: supplement_copy {
                html
              }
              copy2: supplement_copy2 {
                html
              }
              image: supplement_img {
                url
              }
              whatToBring: what_to_bring {
                text
                html
              }
            }
          }
        }
        nursingSupplemental: nursing_supplemental {
          ... on PrismicSkilledNursingResidencePageDataNursingSupplementalSupplement {
            primary {
              title: supplement_title
              copy: supplement_copy {
                html
              }
            }
          }
        }
      }
    }
  }
`
